<template>
  <RFModal :title="isApartment ? accommodation.name : `${reservation.buildingName} - ${reservation.roomType.name}` | replaceUnderscore" :modalId="modalId" hide-footer>
    <object class="manual-guide" :data="manualGuidePath" type="application/pdf"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import { mapState } from 'vuex'
import { docTypes } from '@/enums'
import { getAsset } from '@/helpers/filters'

export default {
  name: 'ManualGuideModal',
  components: {
    RFModal,
  },
  data() {
    return {
      modalId: 'manual-guide-modal',
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
    }),
    manualGuide() {
      return this.reservation ? this.getManualGuide() : null
    },
    accommodation() {
      return this.reservation ? this.reservation.apartment || this.reservation.room : null
    },
    isApartment() {
      return this.reservation ? !!this.reservation.apartment : null
    },
    manualGuidePath() {
      return this.reservation ? getAsset(this.manualGuide.path) : null
    }
  },
  methods: {
    getManualGuide() {
      const index = this.reservation.documents.findIndex(document => document.docType === docTypes.MANUAL_GUIDE)
      return this.reservation.documents[index]
    }
  }
}
</script>

<style>

</style>
