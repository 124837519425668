<template>
  <RFModal title="Payments History" :modalId="modalId" hide-footer >
    <RFTable :items="payments" :fields="fields"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import RFTable from '@/components/forms/RFTable'
import {mapState} from 'vuex'

export default {
  name: 'PaymentsHistoryModal',
  components: {
    RFModal,
    RFTable,
  },
  data() {
    return {
      modalId: 'payments-history-modal',
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
    }),
    creditCard() {
      return this.reservation.cardDetails
    },
    payments() {
      return this.reservation.payments
    },
    fields() {
      const isCreditCard = this.creditCard != null ? 'datetime' : 'date';
      return [
        { label: 'Execution Date', key: 'executionDate', type: isCreditCard},
        { label: 'Expected Date', key: 'expectedDate', type: 'date' },
        { label: 'Amount', key: 'amount', type: 'cash' },
        { label: 'Status', key: 'status', type: 'status' },
        { label: 'Recurring', key: 'isRecurrent', type: 'boolean' },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  @include font-style($montserrat, 'semibold', $font-20);
  text-transform: capitalize;
  color: $white;
  margin: 0;
}
.payments {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;

  .payments-field {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    text-transform: uppercase;
    text-align: center;
  }
  .payment {
    padding: 10px 0;
    border-bottom: 1px solid $gray;

    &:hover {
      background-color: $gray;
    }
    &:last-child {
      border-bottom: none;
    }
    .payment-field {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $dark-blue;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
