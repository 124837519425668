<template>
  <RFModal :title="showTickets ? 'Ticket list' : showSendMessage ? 'Send message' : 'New ticket'" :modalId="modalId" :hide-footer="showTickets"
  :loading="loading" ok-label="Send" @send="send">
    <div class="switch show-ticket-list" v-if="!showTickets && !showSendMessage" @click="showTickets = true">
      <FontAwesomeIcon :icon="['fas', 'list']"/> {{$t('Show tickets')}}
    </div>
    <div class="switch add-ticket" v-if="showTickets && !showSendMessage" @click="showTickets = false">
      <FontAwesomeIcon :icon="['fas', 'plus-circle']"/> {{$t('Add new ticket')}}
    </div>
    <div class="switch back" v-if="showSendMessage" @click="showSendMessage = false">
      <FontAwesomeIcon :icon="['fas', 'arrow-left']"/> {{$t('Back')}}
    </div>

    <RFTable class="mt-4" v-if="showTickets && !showSendMessage" :items="ticketList" :fields="fields" @send="openAddMessage"/>

    <ValidationObserver ref="validator" v-if="!showTickets && !showSendMessage">
      <form class="ticket-form" @submit.prevent>
        <RFValidation rules="required" name="Title">
          <RFInput class="mb-3" label="Title" v-model="ticket.title" :value="ticket.title"/>
        </RFValidation>

        <RFValidation rules="required" name="Description">
          <RFTextArea :height="200" label="Description" v-model="ticket.description" :value="ticket.description"/>
        </RFValidation>

        <div class="ticket-images" :class="{ empty: !ticket.files.length }">
          <b-row v-if="ticket.files.length">
            <b-col class="mb-3" cols="12" md="6" lg="4" v-for="(file, index) in ticket.files" :key="index">
              <div class="ticket-image">
                <img class="w-100" :src="file | getPreview" />
                <div class="hover">
                  <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'trash']" color="#fff" @click="ticket.files.splice(index, 1)"/>
                </div>
              </div>
            </b-col>
          </b-row>
          <div v-else class="rf-gray-color montserrat-medium">{{$t('Upload images')}}</div>
        </div>
        <div class="d-flex justify-content-center">
          <RFFile class="mt-3" :accepted-files="['jpeg', 'png', 'jpg']" size="small" label="Upload" @files="ticket.files.push(...$event)" multiple/>
        </div>
      </form>
    </ValidationObserver>

    <div class="ticket-messages" v-show="showSendMessage" ref="messages">
      <div class="message-container" :class="{ user: !message.isAdmin }" v-for="(message, index) in selectedTicket.messages" :key="`ticket-message-${index}`">
        <div class="message mb-2">
          {{message.content}}
          <div class="date-time">{{message.creationDateTime | formatDateTime}}</div>
        </div>
      </div>
    </div>
    <ValidationObserver ref="messageValidator" v-if="showSendMessage">
      <form @submit.prevent class="mt-3">
        <b-row>
          <b-col>
            <RFValidation name="Message" rules="required" mode="passive">
              <RFTextArea label="Message" v-model="message.content" />
            </RFValidation>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton label="" :icon="['fas', 'paper-plane']" @on-click="sendMessage" v-if="!loadingMessage"/>
            <RFLoader :size="30" color="#24425B" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </RFModal>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFTextArea from '@/components/forms/RFTextArea'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import RFTable from '@/components/forms/RFTable'
import RFModal from '@/components/modals/RFModal'
import RFFile from '@/components/forms/RFFile'

import { ticketService } from '@/services'
import { mapState } from 'vuex'

export default {
  name: 'TicketModal',
  components: {
    RFInput,
    RFTable,
    RFTextArea,
    RFButton,
    RFLoader,
    RFValidation,
    RFModal,
    RFFile,
  },
  data() {
    return {
      modalId: 'ticket-modal',
      ticketList: [],
      ticket: {
        title: null,
        description: null,
        files: [],
      },
      loading: false,
      showTickets: true,

      showSendMessage: false,
      message: {
        content: null,
      },
      selectedTicket: {
        messages: [],
      },
      loadingMessage: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
    }),
    accommodation() {
      return this.reservation.room || this.reservation.apartment
    },
    fields() {
      return [
        { key: 'itemName', label: 'Item Name' },
        { key: 'itemCode', label: 'Item Code' },
        { key: 'type', label: 'Type' },
        { key: 'title', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'openDate', label: 'Open Date', type: 'date' },
        { key: 'closedDate', label: 'Closed Date', type: 'date' },
        { key: 'send', label: 'Send Message', type: 'send' },
      ]
    }
  },
  async beforeMount() {
    this.ticketList = this.reservation.tickets

    if (!this.ticketList.length) {
      this.showTickets = !this.ticketList
    }
  },
  watch: {
    selectedTicket: {
      deep: true,
      handler() {
        this.scrollMessages()
      }
    },
    showSendMessage: {
      handler() {
        this.scrollMessages()
      }
    }
  },
  methods: {
    scrollMessages() {
      window.setTimeout(() => {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
      }, 100)
    },
    openAddMessage(ticket) {
      this.selectedTicket = ticket
      this.showTickets = false
      this.showSendMessage = true
    },
    async send() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        const formData = new FormData()
        const { title, description } = this.ticket
        const ticket = new Blob([JSON.stringify({ title, description })], { type: 'application/json' })

        formData.append('ticket', ticket)
        this.ticket.files.forEach(file => formData.append('files', file))

        const { status } = await ticketService.createTicket(formData, this.reservation.id)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Ticket correctly sended!`))
          this.$root.$emit('bv::hide::modal', this.modalId)
          this.$router.push({ name: 'your-roof' })
        }
      }
    },
    async sendMessage() {
      const isValid = await this.$refs.messageValidator.validate()

      if (isValid) {
        this.loadingMessage = true

        const { data: message, status } = await ticketService.addMessage(this.reservation.id, this.selectedTicket.id, this.message)

        if (status === 200) {
          this.selectedTicket.messages.push(message)
        } else {
          this.$toasted.success(this.$i18n.t(`Error during add ticket message`))
        }

        this.message.content = null
        this.loadingMessage = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  @include font-style($montserrat, 'semibold', $font-14);
  cursor: pointer;
  text-align: right;
  width: fit-content;
  margin-bottom: 20px;

  &.show-ticket-list,
  &.back {
    color: $dark-blue;
  }
  &.add-ticket {
    color: $success;
  }
}
.ticket-form {
  margin-top: 30px;

  .ticket-images {
    border: 1px solid $dark-blue;
    margin-top: 20px;
    padding: 20px 20px 0 20px;

    &.empty {
      height: 200px;
    }
    .ticket-image {
      position: relative;

      .hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($black, .7);
        opacity: 0;
        transition: opacity .3s;
      }

      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }
  }
}
.ticket-messages {
  height: 60vh;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;

  .message-container {
    margin: 0 10px;
    display: flex;
    justify-content: flex-start;

    &.user {
      justify-content: flex-end;

      .message {
        background-color: $dark-blue;
      }
    }
    .message {
      @include font-style($montserrat, 'medium', $font-14);
      background-color: $green;
      border-radius: 5px;
      color: $white;
      padding: 5px 10px;
      width: fit-content;
      max-width: 50%;
      word-break: break-all;
      .date-time {
        @include font-style($montserrat-light, 'light', $font-14);
        text-align: right;
      }
    }
  }
}
</style>