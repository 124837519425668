<template>
  <div class="your-roof-detail">
    <h1 class="title">{{$t('Your Roof')}}</h1>
    <div class="detail-menu" v-if="sections && sections.length">
      <b-row class="menu-row" v-for="(section, index) in sections" :key="`section-${index}`">
        <b-col>
          <h5 class="detail-title">{{$t(section.title)}}</h5>
          <p class="detail-subtitle">{{$t(section.subtitle)}}</p>
        </b-col>
        <b-col cols="3">
          <RFButton full-width label="View" background="#fff" color="#24425B" @on-click="section.function"/>
        </b-col>
      </b-row>
    </div>
    <div class="important-comunications">
      <h2 class="title">{{$t('Important Comunications')}}</h2>

      <div class="comunications" v-if="comunications.length">
        <div class="comunication" v-for="(comunication, index) in comunications" :key="index">
          <div class="date">{{comunication.date}}</div>
          <div class="text">{{comunication.text}}</div>
          <div class="status">{{comunication.status}}</div>
        </div>
      </div>
      <div class="empty-comunications" v-else>
        {{$t('There are no comunications.')}}
      </div>
    </div>
    <ContractModal />
    <PaymentsHistoryModal />
    <!-- <ManualGuideModal /> -->
    <TicketModal />
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import ContractModal from '@/components/modals/ContractModal'
import PaymentsHistoryModal from '@/components/modals/PaymentsHistoryModal'
import ManualGuideModal from '@/components/modals/ManualGuideModal'
import TicketModal from '@/components/modals/TicketModal'

import { mapState } from 'vuex'

export default {
  name: 'YourRoofDetail',
  components: {
    RFButton,
    ContractModal,
    PaymentsHistoryModal,
    TicketModal,
    ManualGuideModal,
  },
  data() {
    return {
      sections: [
        { title: 'Your Contract', subtitle: 'View your contract template. The original copy will be signed once you enter the selected unit', function: this.openContract },
        { title: 'Payments History', subtitle: ' Check out all the payments done and those scheduled for the following months.', function: this.openPaymentsHistory },
        // { title: 'Manual Guide', subtitle: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit!', function: this.openManualGuide },
        { title: 'Something not work?', subtitle: 'Open a ticket so we can fix the problem as soon as possible.', function: this.openTicket },
      ],
      comunications: [
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
        // { date: '07/10/2020', text: 'IMPORTANT NEWS - Disinfection of common areas will be performed on Tuesday 06/10', status: 'New' },
      ]
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
    })
  },
  methods: {
    openContract() {
      this.$store.commit('contractStore/setReservation', this.reservation)
      this.$root.$emit('bv::show::modal', 'contract-modal')
    },
    openPaymentsHistory() {
      this.$root.$emit('bv::show::modal', 'payments-history-modal')
    },
    openTicket() {
      this.$store.commit('profileStore/setReservation', this.reservation)
      this.$root.$emit('bv::show::modal', 'ticket-modal')
    },
    openManualGuide() {
      this.$store.commit('profileStore/setReservation', this.reservation)
      this.$root.$emit('bv::show::modal', 'manual-guide-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.your-roof-detail {
  position: relative;
  z-index: 2;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    border-bottom: 1px solid $dark-gray;
    margin: 30px 0;
  }
  .detail-menu {
    .menu-row {
      margin-bottom: 50px;
      border-bottom: 1px solid $dark-gray;

      &:last-child {
        border-bottom: none;
      }
      .detail-title {
        @include font-style($montserrat-bold, 'bold', $font-18);
        color: $dark-blue;
        text-transform: uppercase;
      }
      .detail-subtitle {
        @include font-style($montserrat, 'semibold', $font-12);
        color: $dark-blue;
      }
    }
  }
  .important-comunications {
    background-color: $dark-blue;
    padding: 40px 0;
    position: relative;
    height: 100%;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $dark-blue;
      z-index: -1;
    }
    &:after {
      left: 100%;
    }
    &:before {
      left: -100%;
    }
    .title {
      @include font-style($montserrat, 'semibold', $font-20);
      color: $white;
      text-transform: uppercase;
      padding: 0 0 10px 0;
      margin: 0 auto auto 0;
      border-bottom: none;
    }
    .subtitle {
      @include font-style($montserrat, 'semibold', $font-12);
      color: $white;
      margin-bottom: 40px;
    }
    .comunications {
      margin-top: 30px;

      .comunication {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .date {
          width: fit-content;
          background-color: $dark-gray;
          padding: 5px 10px;
          color: $white;
          @include font-style($montserrat, 'medium', $font-12);
          margin-right: 20px;
        }
        .text {
          @include font-style($montserrat, 'medium', $font-12);
          color: $white;
        }
        .status {
          @include font-style($montserrat, 'medium', $font-12);
          color: $white;
          padding: 5px 10px;
          border: 1px solid $dark-gray;
          text-transform: uppercase;
        }
      }
    }
    .empty-comunications {
      @include font-style($montserrat, 'medium', $font-14);
      color: $white;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .your-roof-detail {
    .title {
      margin: 0 30px;
    }
    .detail-menu {
      padding: 0 40px;
      margin: 30px 0;
    }
    .important-comunications {
      padding: 40px;

      &:before {
        display: none;
      }
    }
  }
}
</style>
